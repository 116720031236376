import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PopupMessage from './PopupMessage';
import './Login.css';
import logo from '../assets/logoapp.png';
import footerImage from '../assets/logos.png';
import InputMask from 'react-input-mask';

const Login = () => {
  const [cpf, setCpf] = useState('');
  const [matricula, setMatricula] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    const cpfUnmasked = cpf.replace(/\D/g, ''); // Remove a máscara do CPF
    const matriculaNumber = parseInt(matricula, 10); // Converte a matrícula para número

    try {
      const response = await fetch('https://poc.sagresrg.com.br/auth', {
        method: 'GET',
        headers: {
          Authorization: 'Basic 06747ce234e5ccbfec0a3bd01ec63908d5cfc6b1287f4fbc9dbccf53ac35',
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();

      console.log('Payload da API:', data);

      if (data.status === 'success' && data.data.length > 0) {
        const user = data.data.find(
          (user) => user.login === cpfUnmasked && user.matricula === matriculaNumber
        );

        console.log('Usuário Encontrado:', user);

        if (user) {
          localStorage.setItem('userName', user.name);
          localStorage.setItem('token', 'seuTokenAqui'); // Altere se necessário
          localStorage.setItem('colaboradorId', user.id);
          navigate('/home');
        } else {
          setErrorMessage('Credenciais incorretas');
        }
      } else {
        setErrorMessage('Nenhum usuário encontrado ou erro na resposta');
      }
    } catch (error) {
      console.error('Erro na conexão com o servidor:', error);
      setErrorMessage('Erro na conexão com o servidor');
    }
  };

  return (
    <div className="login-container">
      {errorMessage && <PopupMessage message={errorMessage} onClose={() => setErrorMessage(null)} />}
      <div className="login-card">
        <img src={logo} alt="Logo do App" className="app-logo" />
        <h2 className="login-title">Login</h2>
        <form onSubmit={handleLogin} className="login-form">
          <InputMask
            mask="999.999.999-99"
            value={cpf}
            onChange={(e) => setCpf(e.target.value)}
            className="login-input"
            placeholder="CPF"
            required
          />
          <input
            type="text" // Campo do tipo numérico
            placeholder="Matrícula"
            value={matricula}
            onChange={(e) => setMatricula(e.target.value)}
            className="login-input"
            required
          />
          <button type="submit" className="login-button">Entrar</button>
        </form>
        <img src={footerImage} alt="Rodapé" className="footer-image" />
      </div>
    </div>
  );
};

export default Login;
