import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaPlus, FaUserEdit, FaSignOutAlt } from 'react-icons/fa';
import './Home.css';

function Home() {
  const [username, setUsername] = useState('');
  const [records, setRecords] = useState([]);
  const [units, setUnits] = useState({});
  const [locais, setLocais] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    const name = localStorage.getItem('userName');
    const colaboradorId = localStorage.getItem('colaboradorId');

    if (!token || !colaboradorId) {
      navigate('/login');
    } else {
      setUsername(name || 'Usuário');
      fetchData(parseInt(colaboradorId, 10));
    }
  }, [navigate]);

  const fetchData = async (colaboradorId) => {
    setIsLoading(true);
    try {
      const [unitsResponse, locaisResponse, recordsResponse] = await Promise.all([
        fetch('https://poc.sagresrg.com.br/units', {
          method: 'GET',
          headers: {
            Authorization: 'Basic 06747ce234e5ccbfec0a3bd01ec63908d5cfc6b1287f4fbc9dbccf53ac35',
            'Content-Type': 'application/json',
          },
        }),
        fetch('https://poc.sagresrg.com.br/locais', {
          method: 'GET',
          headers: {
            Authorization: 'Basic 06747ce234e5ccbfec0a3bd01ec63908d5cfc6b1287f4fbc9dbccf53ac35',
            'Content-Type': 'application/json',
          },
        }),
        fetch('https://poc.sagresrg.com.br/registros', {
          method: 'GET',
          headers: {
            Authorization: 'Basic 06747ce234e5ccbfec0a3bd01ec63908d5cfc6b1287f4fbc9dbccf53ac35',
            'Content-Type': 'application/json',
          },
        }),
      ]);

      const [unitsData, locaisData, recordsData] = await Promise.all([
        unitsResponse.json(),
        locaisResponse.json(),
        recordsResponse.json(),
      ]);

      if (unitsData.status === 'success') {
        const unitMap = unitsData.data.reduce((map, unit) => {
          map[unit.id] = unit.nome;
          return map;
        }, {});
        setUnits(unitMap);
      }

      if (locaisData.status === 'success') {
        const locaisMap = locaisData.data.reduce((map, local) => {
          map[local.id] = local.nome;
          return map;
        }, {});
        setLocais(locaisMap);
      }

      if (recordsData.status === 'success') {
        const userRecords = recordsData.data.filter(
          (record) => record.obser === colaboradorId
        );
        setRecords(userRecords);
      }
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('pt-BR');
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userName');
    localStorage.removeItem('colaboradorId');
    navigate('/login');
  };

  const handleNovaSugestaoClick = () => {
    navigate('/fique-atento');
  };

  return (
    <div className="home-container">
      <div className="welcome-card">
        <h1>Bem-vindo, {username}!</h1>
        <div className="icon-buttons">
          <button onClick={handleNovaSugestaoClick} className="icon-card">
            <FaPlus />
            <p>Novo</p>
          </button>
          <button className="icon-card">
            <FaUserEdit />
            <p>Perfil</p>
          </button>
          <button onClick={handleLogout} className="icon-card">
            <FaSignOutAlt />
            <p>Sair</p>
          </button>
        </div>
      </div>

      <div className="ideas-list">
        <h2>Meus registros</h2>
        {isLoading ? (
          <div className="loading-spinner">Carregando...</div>
        ) : records.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>Data</th>
                <th>Unidade</th>
                <th>Local</th>
              </tr>
            </thead>
            <tbody>
              {records.map((record) => (
                <tr key={record.id} className="idea-row">
                  <td>{formatDate(record.datapoc)}</td>
                  <td>{units[record.unidades_id] || 'Unidade Desconhecida'}</td>
                  <td>{locais[record.locais_id] || 'Local Desconhecido'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>Nenhum registro encontrado para este colaborador.</p>
        )}
      </div>
    </div>
  );
}

export default Home;
